import Swup from 'swup';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupHeadPlugin from '@swup/head-plugin';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    new Swup({
      containers: [
        '.main-content',
        '.main-navigation',
        '.js-ajax'
      ],
      plugins: [
        new SwupScriptsPlugin(),
        new SwupScrollPlugin(),
        new SwupHeadPlugin()
      ]
    });
  }
})();
